export function useVideoPlaylistUrl(videoPlaylistId: string) {
  const playlistUrl = computed(() => {
    if (!videoPlaylistId) {
      return '';
    }
  
    if (videoPlaylistId.startsWith('http')) {
      return videoPlaylistId;
    }
  
    return `https://cdn.jwplayer.com/v2/playlists/${videoPlaylistId}`;
  });

  return { playlistUrl };
}