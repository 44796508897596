<template>
  <div class="md:px-[5.625vw]">
    <!-- Wrapper for @tailwindcss/aspect-ratio padding trick -->
    <div class="md:aspect-w-16 md:aspect-h-9">
      <div class="md:max-h-[calc(100vh-5vw)] overflow-hidden md:shadow-m mx-auto md:rounded-[1.25vw]"
           :style="{ clipPath: 'content-box' }"
      >
        <div :id="`video-player-background-${videoPlaylistId}`"
             class="object-cover w-full h-full"
        />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useEmbedJwplayer } from '~/composables/use-embed-jwplayer';
import { useVideoPlaylistUrl } from '~/composables/use-video-playlist-url';

type SectionGamesFeatureProps = {
  videoPlaylistId: string;
}

const props = defineProps<SectionGamesFeatureProps>();

const { playlistUrl } = useVideoPlaylistUrl(props.videoPlaylistId);

function embedJwplayer() {
  if (playlistUrl.value !== '') {
    // @ts-expect-error (jwplayer does not provide types)
    window.jwplayer(`video-player-background-${props.videoPlaylistId}`).setup({
      'controls': false,
      'repeat': true,
      'nextUpDisplay': false,
      'displaydescription': false,
      'stretching': 'fill',
      'mute': true,
      'displaytitle': false,
      'playlist': playlistUrl.value
    });
  }
}

useEmbedJwplayer(embedJwplayer);
</script>