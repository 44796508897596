<template>
  <span id="video-player" />
</template>

<script setup lang="ts">
import { useState, useNuxtApp } from '#imports';
import { onMounted, onUnmounted } from 'vue';
import debounce from 'lodash/debounce';
import { useEmbedJwplayer } from '~/composables/use-embed-jwplayer';
import { useVideoPlaylistUrl } from '~/composables/use-video-playlist-url';

const { $event } = useNuxtApp();
const playlist = useState('video-playlist', () => '');
const { playlistUrl } = useVideoPlaylistUrl(playlist.value);

let player: any;
let playlistTitle: string;
let percentages: Array<number>; // of video played
let timeouts: Array<any> = []; // for storing timeout ID's and clearing them on unmount
let durations = [1, 60, 601]; // of session time in seconds

function getDurationRange(duration: number) {
  if (duration < 60) return '<1min';
  if (duration <= 600) return '1-10min';
  else return '>10min';
}

function handleOnPlaylist(e: any) {
  playlistTitle = e.feedData.title;
  $event('Videos Watched', { props: { 'Open': playlistTitle } });
}

const handleOnTime = debounce(e => {
  const currentPercentage = Math.round(e.currentTime / e.duration * 100);

  percentages.forEach(percentage => {
    if (currentPercentage >= percentage) {
      $event('Videos Watched', { props: { [`Play ${percentage}%`]: player.getPlaylistItem().title ? player.getPlaylistItem().title : 'unknown'  } });
      percentages = percentages.filter(item => item !== percentage);
    }
  });
}, 1000, {
  leading: true,
  maxWait: 1000
});

function handleOnPlaylistItem(e: any) {
  percentages = [25, 50, 75, 100];
  $event('Videos Watched', { props: { 'Play': e.item.title } });
}

function handleNextClick() {
  $event('Videos Watched', { props: { 'Next': player.getPlaylistItem().title } });
}

function embedJwplayer() {
  if (playlist.value !== '') {
    // @ts-expect-error (jwplayer does not provide types)
    player = window.jwplayer('video-player').setup({
      'playlist': playlistUrl.value
    });

    player.on('playlist', handleOnPlaylist);
    player.on('time', handleOnTime);
    player.on('playlistItem', handleOnPlaylistItem);
    player.on('nextClick', handleNextClick);
  }
}

useEmbedJwplayer(embedJwplayer);

onMounted(() => {
  durations.forEach((duration, index) => {
    timeouts[index] = setTimeout(() => {
      if (playlistTitle) $event('Videos Watched', { props: { 'Session duration': `${playlistTitle} (${getDurationRange(duration)})` } });
    }, duration * 1000);
  });
});

onUnmounted(() => {
  timeouts.forEach(timeout => clearTimeout(timeout));
});
</script>