<template>
  <TransitionRoot appear
                  :show="isOpen"
                  as="template"
  >
    <Dialog as="div"
            @close="closeModal"
            class="relative z-40"
    >
      <TransitionChild as="template"
                       enter="duration-300 ease-out"
                       enter-from="opacity-0"
                       enter-to="opacity-100"
                       leave="duration-200 ease-in"
                       leave-from="opacity-100"
                       leave-to="opacity-0"
      >
        <div class="fixed inset-0 bg-indigo" />
      </TransitionChild>

      <div class="fixed inset-0 overflow-y-auto">
        <div class="flex items-center justify-center w-screen min-h-full">
          <TransitionChild as="template"
                           enter="duration-300 ease-out"
                           enter-from="opacity-0 scale-95"
                           enter-to="opacity-100 scale-100"
                           leave="duration-200 ease-in"
                           leave-from="opacity-100 scale-100"
                           leave-to="opacity-0 scale-95"
          >
            <DialogPanel class="my-[6vw] w-[100vh] h-[calc(100vh-16vw)] md:h-[calc(100vh-12vw)] overflow-hidden align-middle flex items-center">
              <button @click="closeModal"
                      class="absolute top-[6vw] right-[6vw] md:top-[2.5vw] md:right-[2.5vw] h-[10vw] w-[10vw] md:h-[2vw] md:w-[2vw] flex justify-center items-center bg-white text-indigo rounded-full hover:opacity-90 duration-300"
              >
                <span class="sr-only">Close the popup</span>
                <IconClose class="h-[5vw] md:h-[1.5vw] w-[5vw] md:w-[1.5vw]" />
              </button>
              <VideoPlayer />
            </DialogPanel>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script setup lang="ts">
/**
 * Video popup that plays a video from a video platform playlist
 *
 * The VideoPlayer has a global state and can be controlled from anywhere.
 * Since it's above the NuxtPage component, the state will persist on page changes.
 */

import { watch } from 'vue';
import { useState, useRoute, useNuxtApp, useRuntimeConfig } from '#imports';
import {
  TransitionRoot,
  TransitionChild,
  Dialog,
  DialogPanel
} from '@headlessui/vue';

const { $pageview } = useNuxtApp();
const route = useRoute();
const { baseUrl } = useRuntimeConfig().public;

const isOpen = useState('video-popup', () => false);

watch(isOpen, value => {
  if (value) {
    $pageview({
      referrer: `${baseUrl}${route.fullPath}`,
      url: `${baseUrl}/video-popup`
    });
  } else {
    $pageview({
      referrer: `${baseUrl}/video-popup`,
      url: `${baseUrl}${route.fullPath}`
    });
  }
});

function closeModal() {
  isOpen.value = false;
}
</script>
