<template>
  <section class="md:px-[5.625vw] md:pt-[5vw] pb-[17.25vw] md:pb-[6.5vw] bg-[#FFD939] bg-[url('https://cdn.outfit7.com/web/campaigns/mthi-gl/header-pattern-mobile.svg')] md:bg-[url('https://cdn.outfit7.com/web/campaigns/mthi-gl/header-pattern-desktop.svg')] bg-[length:100%_auto]">
    <div class="relative flex flex-col justify-end items-center md:mb-[5.75vw] md:rounded-[1.25vw]">
      <NuxtImg format="auto"
               class="mx-auto w-full md:hidden"
               alt="My Talking Hank in a dino outfit running along the beach with animals, tree-house and an opened treasure chest."
               src="https://cdn.outfit7.com/web/campaigns/mthi-gl/hero-mobile.png"
               sizes="xs:100vw sm:100vw"
      />
      <NuxtImg format="auto"
               class="mx-auto hidden md:block w-full"
               alt="My Talking Hank in a dino outfit running along the beach with animals, tree-house and an opened treasure chest."
               src="https://cdn.outfit7.com/web/campaigns/mthi-gl/hero-desktop.png"
               sizes="md:90vw lg:90vw xl:90vw xxl:90vw"
      />
    </div>

    <div class="relative flex flex-col md:flex-row items-center justify-center gap-[8vw] md:gap-[4vw]">
      <NuxtImg format="auto"
               class="absolute left-[4.2vw] top-[72vw] md:top-[2.6vw] w-full max-w-[15vw] md:max-w-[5.5vw]"
               alt="Your next adventure starts here"
               src="https://cdn.outfit7.com/web/campaigns/mthi-gl/heart.png"
               sizes="xs:15vw sm:15vw md:5.5vw lg:5.5vw xl:5.5vw xxl:5.5vw"
      />
      <NuxtImg format="auto"
               class="md:hidden absolute right-0 top-[46.5vw] w-full max-w-[15vw]"
               alt="Your next adventure starts here"
               src="https://cdn.outfit7.com/web/campaigns/mthi-gl/spiral-arrow-mobile.png"
               sizes="xs:15vw sm:15vw"
      />
      <NuxtImg format="auto"
               class="max-md:hidden absolute right-[4vw] -top-[8.5vw] w-full max-w-[7.5vw]"
               alt="Your next adventure starts here"
               src="https://cdn.outfit7.com/web/campaigns/mthi-gl/spiral-arrow.png"
               sizes="md:7.5vw lg:7.5vw xl:7.5vw xxl:7.5vw"
      />
      <NuxtImg format="auto"
               class="md:hidden w-full max-w-[92vw]"
               alt="Your next adventure starts here"
               src="https://cdn.outfit7.com/web/campaigns/mthi-gl/adventure.png"
               sizes="xs:92vw sm:92vw"
      />
      <NuxtImg format="auto"
               class="hidden md:block w-full max-w-[41vw]"
               alt="Your next adventure starts here"
               src="https://cdn.outfit7.com/web/campaigns/mthi-gl/adventure-desktop.png"
               sizes="md:41vw lg:41vw xl:41vw xxl:41vw"
      />
      <div class="flex flex-col gap-[4vw] md:gap-[1.25vw]">
        <ButtonStore :type="ButtonStoreType.APPLE"
                     href="https://o7.onelink.me/hhUd/2efz4odw"
                     class="max-w-[55vw] md:max-w-[16vw]"
                     @click="trackEvent('click', { props: { campaign: campaign.name, position: `landing | appstore` } })"
        />
        <ButtonStore :type="ButtonStoreType.GOOGLE"
                     href="https://o7.onelink.me/hhUd/80let6hy"
                     class="max-w-[55vw] md:max-w-[16vw]"
                     @click="trackEvent('click', { props: { campaign: campaign.name, position: `landing | gplay` } })"
        />
        <ButtonStore :type="ButtonStoreType.HUAWEI"
                     href="https://o7n.co/mkt-mthigl-mthi-ttfweb-huawei"
                     class="max-w-[55vw] md:max-w-[16vw]"
                     @click="trackEvent('click', { props: { campaign: campaign.name, position: `landing | huawei` } })"
        />
        <ButtonStore :type="ButtonStoreType.AMAZON"
                     href="https://o7n.co/mkt-mthigl-mthi-ttfweb-amazon"
                     class="max-w-[55vw] md:max-w-[16vw]"
                     @click="trackEvent('click', { props: { campaign: campaign.name, position: `landing | amazon` } })"
        />
      </div>
      <HankSvgPizza ref="pizza"
                    class="absolute max-md:hidden left-auto -right-[8vw] -bottom-[11vw] w-full max-w-[13vw]"
      />
    </div>
  </section>
</template>

<script setup lang="ts">
import { ref, onMounted, useNuxtApp } from '#imports';
import { animate, scroll } from 'motion';
import { campaign } from '~/data/constants';
import { ButtonStoreType } from '~/enums';

const { $event: trackEvent } = useNuxtApp();

const puzzle = ref(null);
const pizza = ref(null);
const donut = ref(null);

onMounted(() => {
  scroll(({ y }) => {
    if (puzzle.value) {
      animate(puzzle.value.$el,
        { transform: `rotate(${y.current / 4}deg)` },
        { duration:0 }
      );
    }

    if (pizza.value) {
      animate(pizza.value.$el,
        { transform: `rotate(${-y.current / 3}deg)` },
        { duration:0 }
      );
    }

    if (donut.value) {
      animate(donut.value.$el,
        { transform: `rotate(${y.current / 2}deg)` },
        { duration:0 }
      );
    }
  });
});
</script>
