import { VIDEO_PLAYER_URL } from '~/data/constants';

export function useEmbedJwplayer(embedJwplayer: () => void) {
  let scriptElement: HTMLScriptElement;

  onMounted(() => {
    // @ts-expect-error (jwplayer does not provide types)
    if (window.jwplayer) {
      embedJwplayer();
    }
    else {
      scriptElement = document.querySelector(`script[src="${VIDEO_PLAYER_URL}"]`);
      scriptElement.addEventListener('load', embedJwplayer);
    }
  });

  onUnmounted(() => {
    if (scriptElement) scriptElement.removeEventListener('load', embedJwplayer);
  });
}