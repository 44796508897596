<template>
  <div class="md:px-[5.625vw]">
    <!-- Wrapper for @tailwindcss/aspect-ratio padding trick -->
    <div class="md:aspect-w-16 md:aspect-h-9">
      <div @click="openModal()"
           class="cursor-pointer block md:max-h-[calc(100vh-5vw)] group overflow-hidden shadow-m mx-auto md:rounded-[1.25vw] relative"
           :style="{ clipPath: 'content-box' }"
      >
        <div :id="`video-player-background-${videoPlaylistId}`"
             class="object-cover w-full h-full"
        />
        <div class="absolute inset-0 flex items-center translate-y-0 justify-center duration-300 scale-75 md:scale-110 md:opacity-0 md:group-hover:opacity-100">
          <ButtonPlay />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useState } from '#imports';
import { useEmbedJwplayer } from '~/composables/use-embed-jwplayer';
import { useVideoPlaylistUrl } from '~/composables/use-video-playlist-url';

type VideoProps = {
  videoPlaylistId: string;
};

const props = defineProps<VideoProps>();

const isOpen = useState('video-popup', () => false);
const playlist = useState('video-playlist', () => '');

const { playlistUrl } = useVideoPlaylistUrl(props.videoPlaylistId);

function embedJwplayer() {
  if (playlistUrl.value !== '') {
    // @ts-expect-error (jwplayer does not provide types)
    window.jwplayer(`video-player-background-${props.videoPlaylistId}`).setup({
      'controls': false,
      'repeat': true,
      'nextUpDisplay': false,
      'displaydescription': false,
      'stretching': 'fill',
      'mute': true,
      'displaytitle': false,
      'playlist': playlistUrl.value
    });
  }
}

useEmbedJwplayer(embedJwplayer);

function openModal() {
  isOpen.value = true;
  playlist.value = props.videoPlaylistId;
}
</script>
