<template>
  <div class="bg-indigo pt-[16vw] md:pt-[5vw] text-white font-work relative overflow-hidden">
    <Container v-if="content.banner && (geoIpCountry && geoIpCountry !== 'US')"
               class="mb-[-4vw] md:mb-[-2.5vw] md:mt-[3.5vw] mt-[10vw]"
    >
      <CardPromoPrivacy v-if="promoContent"
                        v-bind="promoContent.promo"
      />
    </Container>

    <div v-else>
      <SvgShapeHalfCircle class="absolute text-pink w-[18vw] left-[34vw] top-[13vw] md:w-[12vw] md:left-[-2.75vw] md:top-[11vw]" />
      <SvgShapeCrescent class="rotate-[135deg] absolute top-[1vw] md:top-[-3vw] right-0 text-turquoise h-[40vw] w-[20vw] md:h-[20vw] md:w-[10vw]" />
      <SvgShapeGearSharp class="absolute text-yellow w-[21.5vw] top-[28vw] left-[-8vw] md:top-[-1.5vw] md:left-[23vw] md:w-[13.375vw]" />
    </div>

    <Container class="py-[20vw] md:py-[10vw]">
      <h1 class="text-[14vw] md:text-[6vw] leading-none tracking-[-0.01em] font-extrabold md:max-w-[64vw] mx-auto text-center">
        {{ content.title }}
      </h1>

      <div class="mt-[10vw] md:mt-[5vw] md:columns-3 gap-[6vw] md:gap-x-[2vw] ">
        <div v-for="(item, index) in content.items"
             :key="index"
             class="bg-purple inline-flex break-inside-avoid md:mb-[2vw] mb-[6vw] md:rounded-[1.25vw] py-[12vw] px-[6vw] rounded-[5vw] md:p-[3vw] w-full flex-col items-center text-center"
        >
          <h2 class="font-extrabold md:text-[2.5vw] md:mb-[1.5vw] tracking-[-0.01em] leading-none text-[10vw] mb-[6vw]">
            {{ item.title }}
          </h2>
          <p class="text-[4.25vw] leading-[6vw] md:text-[1.0625vw] md:mb-[2vw] md:leading-[1.5vw] mb-[8vw]">
            {{ item.description }}
          </p>
          <ButtonRegular v-bind="item.cta">
            {{ item.cta.label }}
          </ButtonRegular>
        </div>
      </div>
    </Container>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import { useAsyncData, queryContent } from '#imports';
import useGeoIp from '~/composables/useGeoIp';

type TemplatePrivacyProps = {
  content: any
}

defineProps<TemplatePrivacyProps>();

const { geoIpCountry, geoLocate } = useGeoIp();
geoLocate();

const { data } = await useAsyncData('general', () => queryContent('globals', 'general').findOne());

const promoContent = computed(() => {
  if (data && data.value && data.value.promo) {
    return data.value;
  }

  return false;
});
</script>