<template>
  <div class="md:rounded-[1.25vw] overflow-hidden relative rounded-[5vw] text-white"
       style="filter: drop-shadow(0px 20px 80px rgba(29, 29, 39, 0.3));"
       :style="{ clipPath: 'content-box' }"
  >
    <NuxtImg format="auto"
             :src="backgroundDesktop"  
             alt=""
             class="absolute inset-0 hidden object-cover w-full h-full md:block"
             sizes="md:120vw lg:120vw xl:120vw xxl:120vw"
             preload
    />

    <NuxtImg format="auto"
             :src="backgroundMobile"
             alt=""
             class="absolute inset-0 object-cover w-full h-full md:hidden rounded-[5vw]"
             sizes="xs:100vw sm:100vw"
             preload
    />

    <div v-if="darkOverlay"
         class="absolute inset-0 w-full h-full rounded-[5vw] md:hidden bg-black/30"
    />

    <div class="relative md:pt-[2.5vw] md:pb-[2.75vw] pt-[5vw] pb-[6vw] px-[5vw] md:px-0 md:pl-[4vw] md:max-w-[42vw] flex flex-col items-center md:items-start">
      <div class="flex md:flex-col w-full mb-[20vw] md:mb-0 items-center md:items-start">
        <GameIcon class="w-[16vw] h-[16vw] md:w-[5vw] md:h-[5vw] shrink-0 mr-[4vw] md:mr-0"
                  :src="icon"
        />

        <h2 class="md:mt-[1.5vw] md:mb-[2vw] md:leading-none tracking-[-0.01em] font-extrabold md:text-[3.5vw] text-[6vw] leading-[7vw] md:text-left"
            :class="`text-${titleColor}`"
            v-html="title"
        />
      </div>

      <ButtonRegular v-bind="cta"
                     class="relative"
                     @click="trackEvent('click', { props: { campaign: campaign.name, position: `privacy | ${CampaignCreative.GENERAL}` } })"
      >
        {{ cta.label }}
      </ButtonRegular>
    </div>
  </div>
</template>

<script setup lang="ts">
import type { CtaType } from '../../types';
import { useNuxtApp } from '#imports';
import { campaign, CampaignCreative } from '~/data/constants';

type CardPromoPrivacyProps = {
    backgroundDesktop: string;
    backgroundMobile: string;
    icon: string;
    title: string;
    cta: CtaType;
    titleColor?: string;
    darkOverlay?: boolean;
}

withDefaults(defineProps<CardPromoPrivacyProps>(), {
  titleColor: 'white',
  darkOverlay: true
});

const { $event: trackEvent } = useNuxtApp();
</script>
